<template>
	<div class="grid">
		<div class="md:col-8 col-12">
			<Card>
                <template #title>
                    {{title}}
                </template>
                <template #content>
                    <div class="p-fluid">
                        <div class="field">
                            <label>Judul</label>
                            <InputText v-model="dataForm.title" autofocus
                                :class="{ 'p-invalid': dataForm.errors.has('title') }" />
                            <small class="p-error" v-show="dataForm.errors.has('title')">
                                {{ dataForm.errors.get('title') }}
                            </small>
                        </div>
                        <div class="fild">
                            <label>Kontent</label>
                            <Editor
								v-model="dataForm.content"
								:class="{ 'p-invalid': dataForm.errors.has('content') }"
								:api-key="$tinymce_api_key"
								:init="{
									height: 400,
									plugins: 'print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media codesample table charmap hr nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons',
									menubar: 'file edit view insert format tools table help',
									toolbar: 'undo redo | bold italic underline strikethrough | charmap emoticons | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | image media link anchor codesample | ltr rtl',
									valid_children: '+body[style]'
								}"
							/>
							<small class="p-error" v-show="dataForm.errors.has('content')">
								{{ dataForm.errors.get('content') }}
							</small>
                        </div>
                    </div>
				</template>
			</Card>
		</div>
        <div class="md:col-4 col-12">
            <div class="grid">
				<div class="col-12">
					<Card>
						<template #title>Publikasi</template>
						<template #content>
							<div class="p-fluid">
								<div class="grid">
									<div class="col-6">
										<div class="field">
											<label>Status</label>
											<Dropdown v-model="dataForm.status" :options="relations.status"
												optionValue="id"
												optionLabel="name"
												:class="{ 'p-invalid': dataForm.errors.has('status') }" />
											<small class="p-error" v-show="dataForm.errors.has('status')">
												{{ dataForm.errors.get('status') }}
											</small>
										</div>
									</div>
									<div class="col-6">
										<div class="field">
											<label>Akses</label>
											<Dropdown v-model="dataForm.visibility" :options="relations.visibility"
												optionValue="id"
												optionLabel="name"
												:class="{ 'p-invalid': dataForm.errors.has('visibility') }" />
											<small class="p-error" v-show="dataForm.errors.has('visibility')">
												{{ dataForm.errors.get('visibility') }}
											</small>
										</div>
									</div>
								</div>
								<div v-if="dataForm.type == 'post'" class="field">
									<label>Komentar</label>
									<Dropdown v-model="dataForm.comment_status" :options="relations.comment_status"
										optionValue="id"
										optionLabel="name"
										:class="{ 'p-invalid': dataForm.errors.has('comment_status') }" />
									<small class="p-error" v-show="dataForm.errors.has('comment_status')">
										{{ dataForm.errors.get('comment_status') }}
									</small>
								</div>
							</div>
						</template>
						<template #footer>
							<!-- <Button label="Reset" icon="pi pi-sync" @click="resetForm" class="p-button-info mr-1"/> -->
							<Button @click="submitData" label="Simpan" :disabled="dataForm.busy" :icon="(dataForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-send'" class="p-button-info"/>
						</template>
					</Card>
				</div>
				<div v-if="dataForm.type == 'post'" class="col-12">
					<Card>
						<template #title></template>
						<template #content>
							<div class="p-fluid">
								<div class="field">
									<label>Kategori</label>
									<div v-for="(category, i) of relations.category" :key="i" class="field-checkbox">
										<Checkbox v-model="dataForm.category" 
											:value="category.id" 
											:id="'category-'+category.id" 
											:class="{ 'p-invalid': dataForm.errors.has('category') }"/>
										<label :for="'category-'+category.id">{{category.name}}</label>
									</div>
									<small class="p-error" v-show="dataForm.errors.has('category')">
										{{ dataForm.errors.get('category') }}
									</small>
								</div>
								<div class="field">
									<label>Gambar</label> (maxsize:10mb)
									<InputText @change="selectFile" type="file" accept="image/*"
										:class="{ 'p-invalid': dataForm.errors.has('image') }" />
									<small class="p-error" v-show="dataForm.errors.has('image')">
										{{ dataForm.errors.get('image') }}
									</small>
									<!-- <Image :src="postImage" imageStyle="width: 100%;" preview /> -->
								</div>
							</div>
						</template>
						<template #footer></template>
					</Card>
				</div>
			</div>
        </div>
	</div>

</template>

<script>
export default {
	props: ['id'],
	data() {
		return {
			title: '',
			api: '/api/posts',
			dataForm: new this.$FormAuth({
				mode: null,
				id: null,
				title: null,
				content: null,
				image: null,
				type: null,
				status: 'publish',
				visibility: 'public',
				comment_status: 'open',
				category: null,
			}),
			relations: {
                status: null,
				visibility: null,
                comment_status: null,
				category: null,
			},
		}
	},
	computed: {
		postImage() {
			return process.env.VUE_APP_DEFAULT_NO_IMAGE
		}
	},
	methods: {
		loadData(id){
			return new Promise((resolve, reject) => {
				this.$Progress.start();
				this.$httpAuth.get(`${this.api}/${id}/edit`)
				.then((response) => {
					this.$Progress.finish();
					this.dataForm.fill(response.data);
					return resolve(response);
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
					return reject(error)
				});
			})
		},

		selectFile(e){
			const file = e.target.files[0];
			this.dataForm.image = file;
		},

		getStatus(){
            this.$httpAuth.get(this.api + '/get/status')
            .then((response) => {
                this.relations.status = response.data;
            })
            .catch((error) => {
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },
		getVisibility(){
            this.$httpAuth.get(this.api + '/get/visibility')
            .then((response) => {
                this.relations.visibility = response.data;
            })
            .catch((error) => {
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },
		getCommentStatus(){
            this.$httpAuth.get(this.api + '/get/comment-status')
            .then((response) => {
                this.relations.comment_status = response.data;
            })
            .catch((error) => {
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },
		getCategory(){
            this.$httpAuth.get(this.api + '/get/category')
            .then((response) => {
                this.relations.category = response.data;
            })
            .catch((error) => {
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },

		resetForm(){
			this.dataForm.clear();
			this.dataForm.reset();
		},
		submitData(){
			this.$Progress.start();
			if(this.dataForm.mode == 'add'){
				this.dataForm.post(this.api)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.$router.push((this.dataForm.type == 'post') ? '/dashboard/posts' : (this.dataForm.type == 'page') ? '/dashboard/pages' : '/dashboard/posts')
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			} else if(this.dataForm.mode == 'edit') {
				this.dataForm.post(this.api + '/' + this.dataForm.id)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.$router.push((this.dataForm.type == 'post') ? '/dashboard/posts' : (this.dataForm.type == 'page') ? '/dashboard/pages' : '/dashboard/posts')
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			}
		},
	},
	created(){
		this.dataForm.mode = (this.id) ? 'edit' : 'add';
		this.title += (this.dataForm.mode == 'add') ? 'Tambah' : (this.dataForm.mode == 'edit') ? 'Edit' : '';
		if (this.dataForm.mode == 'add') {
			this.dataForm.type = (this.$route.path.includes('post')) ? 'post' : (this.$route.path.includes('page')) ? 'page' : '';
			this.dataForm.comment_status = (this.$route.path.includes('post')) ? 'open' : (this.$route.path.includes('page')) ? 'close' : '';
			this.title += (this.dataForm.type == 'post') ? ' Berita' : (this.dataForm.type == 'page') ? ' Halaman' : '';
			this.$e.emit('updateTitle', this.title);
		} else if (this.dataForm.mode == 'edit') {
			this.loadData(this.id).then(() => {
				this.title += (this.dataForm.type == 'post') ? ' Berita' : (this.dataForm.type == 'page') ? ' Halaman' : '';
				this.$e.emit('updateTitle', this.title);
				this.dataForm.mode = 'edit';
			}).catch(() => {
				return this.$router.push('/dashboard/posts')
			});	
		}
	},
	mounted() {
		
		this.getStatus();
		this.getVisibility();
		this.getCommentStatus();
		this.getCategory();
	},
}
</script>

<style scoped>
.p-image-preview-container {
    width: 100%;
}
</style>